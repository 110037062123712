/* this file is reused across public and admin modules. */
/* please don't polute it*/
/* avatar */
.cx-avatar {
  display: inline-block;
  vertical-align: middle;

  div, img {
    font-family: sans-serif;
    text-align: center;
    border-radius: 50%;
  }

  .cx-avatar-initials {
    color: white;
    border-radius: 50%;
    /* default shading, should be overriden in elements */
    background-color: lightgray;
    box-shadow: gray 0px 0px 10px inset;
  }

  &-square {
    div, img,
    .cx-avatar-initials {
      border-radius: 4px;
    }
  }

  .cx-avatar-message {
    color: black;
    background-color: white;
    -webkit-box-shadow: black 0px 0px 10px inset;
    -ms-box-shadow: black 0px 0px 10px inset;
    box-shadow: black 0px 0px 10px inset;
  }

  span {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
  }
}

.cx-avatar-lg {

  div, img {
    height: 75px;
    width: 75px;
  }

  .cx-avatar-initials {
    line-height: 75px;
    font-size: 50px;
  }

  .cx-avatar-message {
    line-height: 70px;
    font-size: 16px;
  }

  span {
    width: 65px;
  }
}

.cx-avatar-xxlg {

  div, img {
    height: 150px;
    width: 150px;
  }

  .cx-avatar-initials {
    line-height: 150px;
    font-size: 135px;
  }

  .cx-avatar-message {
    line-height: 145px;
    font-size: 16px;
  }

  span {
    width: 140px;
  }
}

.cx-avatar-xlg {
  div, img {
    height: 120px;
    width: 120px;
  }

  .cx-avatar-initials {
    line-height: 120px;
    font-size: 95px;
  }

  .cx-avatar-message {
    line-height: 115px;
    font-size: 16px;
  }

  span {
    width: 110px;
  }
}

.cx-avatar-md {

  div, img {
    height: 54px;
    width: 54px;
  }

  .cx-avatar-initials {
    line-height: 54px;
    font-size: 50px;
  }

  .cx-avatar-message {
    line-height: 52px;
    font-size: 12px;
  }

  span {
    width: 50px;
  }
}

.cx-avatar-sm {

  div, img {
    height: 38px;
    width: 38px;
  }

  .cx-avatar-initials {
    line-height: 38px;
    font-size: 35px;
  }

  .cx-avatar-message {
    line-height: 35px;
    font-size: 8px;
  }

  span {
    width: 35px;
  }
}

.cx-avatar-xs {

  div, img {
    height: 28px;
    width: 28px;
  }

  .cx-avatar-initials {
    line-height: 28px;
    font-size: 25px;
  }

  .cx-avatar-message {
    line-height: 25px;
    font-size: 5px;
  }

  span {
    width: 25px;
  }
}

.cx-avatar-ticker {
  margin: 0 0 20px;
  // @dgonchar: there is no <img> tag.
  div {
    padding: 10px 25px;
  }

  .cx-avatar-initials {
    font-size: 42px;
    background-color: #cb7f7f;
  }
}

/* end of avatar */
